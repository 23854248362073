import * as React from "react";
import * as styles from "./Carousel.module.scss";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { nanoid } from "nanoid";
import { getImage } from "../contentful/data-provider";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Carousel = ({ slides }) => {
	const splideRef = React.useRef(null);
	const progressBarRef = React.useRef(null);
	const progressYearRefs = React.useRef([]);
	const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
	const [lastSlideIndex, setLastSlideIndex] = React.useState(0);

	const observer = React.useRef(null);

	let observerOptions = {
		rootMargin: "0px",
		threshold: 1.0,
	};

	function observerCallback(entries, observer) {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				entry.target.dataset.inview = "true";
			} else {
				entry.target.dataset.inview = "false";
			}
		});
	}

	if (progressYearRefs.current && progressYearRefs.current.length > 0) {
		progressYearRefs.current.forEach((item, index) => {
			observer.current.observe(item);
		});
	}
	React.useEffect(() => {
		observer.current = new IntersectionObserver(observerCallback, observerOptions);
		if (splideRef.current) {
			setLastSlideIndex(splideRef.current.splide.length - 1);
		}
		return () => {};
	}, []);

	const handleYearClick = (index) => {
		if (splideRef.current) {
			splideRef.current.splide.Components.Controller.go(index);
		}
	};

	const handleSlideChange = (index, newIndex, oldIndex, destIndex) => {
		let direction = "fw";
		if (newIndex < oldIndex) direction = "bw";
		if (splideRef.current && progressYearRefs.current) {
			if (direction === "fw") {
				if (progressBarRef.current.getBoundingClientRect().width < progressYearRefs.current[index].getBoundingClientRect().right) {
					progressBarRef.current.scrollLeft +=
						progressYearRefs.current[index].getBoundingClientRect().right - progressBarRef.current.getBoundingClientRect().width;
				}
			} else {
				if (progressBarRef.current.getBoundingClientRect().left > progressYearRefs.current[index].getBoundingClientRect().left) {
					progressBarRef.current.scrollLeft -=
						progressBarRef.current.getBoundingClientRect().left - progressYearRefs.current[index].getBoundingClientRect().left;
				}
			}
			setActiveSlideIndex(index);
		}
	};


	const renderSlides = () => {
		return slides.map((slide, index) => {
			return (
				<SplideSlide key={nanoid()} slide={slide} className={[styles.item, !slide.image ? styles.itemWithoutImage : "", activeSlideIndex === index ? styles.visibleItem : null].join(" ")}>
					<div className={[styles.year, !slide.image ? styles.yearWithoutImage : ""].join(" ")}>{slide.year}</div>
					{slide.image && (
						<div className={styles.imageContainer}>
							<img className={styles.image} src={getImage(slide.image).url} alt={getImage(slide.image).title} />
						</div>
					)}
					<div className={[styles.description, !slide.image ? styles.descriptionWithoutImage : ""].join(" ")}>
						{renderRichText(slide.text)}
					</div>
				</SplideSlide>
			);
		});
	};

	const renderCarouselProgressBar = () => {
		return slides.map((slide, index) => {
			return (
				<div key={nanoid()} onClick={() => handleYearClick(index)} className={styles.progressBarItem}>
					<div
						className={[styles.progressYear, activeSlideIndex === index ? styles.progressYearActive : null].join(" ")}
						ref={(element) => {
							progressYearRefs.current[index] = element;
						}}
					>
						{slide.year}
					</div>
				</div>
			);
		});
	};

	return (
		<div className={styles.container}>
			<Splide
				ref={splideRef}
				hasTrack={false}
				options={{
					pagination: false,
				}}
				onMove={(splide, newIndex, oldIndex, destIndex) => {
					handleSlideChange(splide.index, newIndex, oldIndex, destIndex);
				}}
			>
				<div className={styles.wrapper}>
					<SplideTrack className={styles.track}>{renderSlides()}</SplideTrack>
					<div className={["splide__arrows", styles.buttons].join(" ")}>
						<button
							className={[
								"splide__arrow splide__arrow--prev",
								styles.button,
								activeSlideIndex === 0 ? styles.buttonInactive : null,
							].join(" ")}
						>
							<svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17'>
								<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
									<g transform='translate(-1354 -1747)'>
										<g transform='translate(1331.069 1722.931)'>
											<g transform='rotate(-135 29.81 25.81)'>
												<rect width='46.156' height='45.962' x='0' y='0' rx='22.981'></rect>
												<g stroke='#0E4C8B' strokeWidth='1.5' transform='translate(11.613 11.785)'>
													<g transform='rotate(45 5.665 13.677)'>
														<path
															strokeLinejoin='round'
															d='M13.542 2.291L2.481 13.353'
															transform='rotate(-45 8.012 7.822)'
														></path>
														<path
															d='M13.2170893 13.677145L13.6776392 2.34691655 2.34642243 2.80647814'
															transform='rotate(-45 8.012 8.012)'
														></path>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</button>
						<button
							className={[
								"splide__arrow splide__arrow--next",
								styles.button,
								lastSlideIndex === activeSlideIndex ? styles.buttonInactive : null,
							].join(" ")}
						>
							<svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17'>
								<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
									<g transform='translate(-1435 -1747)'>
										<g transform='translate(1331.069 1722.931)'>
											<g transform='rotate(45 56.112 135.467)'>
												<rect width='46.156' height='45.962' x='0' y='0' rx='22.981'></rect>
												<g stroke='#0E4C8B' strokeWidth='1.5' transform='translate(11.613 11.785)'>
													<g transform='rotate(45 5.665 13.677)'>
														<path
															strokeLinejoin='round'
															d='M13.542 2.291L2.481 13.353'
															transform='rotate(-45 8.012 7.822)'
														></path>
														<path
															d='M13.2170893 13.677145L13.6776392 2.34691655 2.34642243 2.80647814'
															transform='rotate(-45 8.012 8.012)'
														></path>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</button>
					</div>
				</div>
			</Splide>
			<div className={styles.progressWrapper}>
				<div className={styles.progressBar} ref={progressBarRef}>
					{renderCarouselProgressBar()}
				</div>
			</div>
		</div>
	);
};

export default Carousel;

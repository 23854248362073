// extracted by mini-css-extract-plugin
export var button = "Carousel-module--button--27a95";
export var buttonInactive = "Carousel-module--buttonInactive--194ac";
export var buttons = "Carousel-module--buttons--06fa7";
export var container = "Carousel-module--container--1f4bc";
export var description = "Carousel-module--description--a3126";
export var descriptionWithoutImage = "Carousel-module--descriptionWithoutImage--2fe46";
export var imageContainer = "Carousel-module--imageContainer--6ee99";
export var item = "Carousel-module--item--991af";
export var progressBar = "Carousel-module--progressBar--7c146";
export var progressBarItem = "Carousel-module--progressBarItem--87d62";
export var progressWrapper = "Carousel-module--progressWrapper--7b2a2";
export var progressYear = "Carousel-module--progressYear--e4a67";
export var progressYearActive = "Carousel-module--progressYearActive--5ff0e";
export var visibleItem = "Carousel-module--visibleItem--eef09";
export var wrapper = "Carousel-module--wrapper--ceacd";
export var year = "Carousel-module--year--978c7";
export var yearWithoutImage = "Carousel-module--yearWithoutImage--16ca5";
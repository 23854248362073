import * as React from 'react';
import * as styles from './FullWidthImage.module.scss';

const FullWidthImage = ({src, textBlock, isFoilProcessingSection}) => {
    return (
        <div className={styles.container}>
            <img className={[
                styles.image,
                isFoilProcessingSection ? styles.isFoilProcessingSection : null,
            ].join(' ')
            } src={src} alt={''}/>
            {textBlock}
        </div>
    )
}

export default FullWidthImage;
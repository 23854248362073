import * as React from 'react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import FullWidthImage from '../../components/FullWidthImage';
import Carousel from '../../components/Carousel';
import {graphql} from 'gatsby';
import {getImage} from "../../contentful/data-provider"

const HistoryPage = ({data}) => {
    const {contentfulBasicPageSection, contentfulHistorySection} = data;
    const replaceForBlueText = (text) => {
        return text.replace(/(<!)+/g, '<span>').replace(/(!>)+/g, '</span>');
    }
    return (
        <>
            <Layout>
                <main>
                    <PageHeader>
                        <PageTitle>
                            <div>{contentfulBasicPageSection.subtitle}</div>
                            <h1 dangerouslySetInnerHTML={{__html: replaceForBlueText(contentfulBasicPageSection.title)}}/>
                        </PageTitle>
                    </PageHeader>
                    <Section isHistoryImageSection>
                        {contentfulBasicPageSection.images.length >= 1 &&
                            <FullWidthImage src={getImage(contentfulBasicPageSection.images[0]).url}/>
                        }
                    </Section>
                    <Section hasMaxWidth isHistoryCarouselSection>
                        <Carousel slides={contentfulHistorySection.elements}/>
                    </Section>
                </main>
            </Layout>
        </>
    )
}

export default HistoryPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title.replace(/(<!)+/g, '').replace(/(!>)+/g, '')} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection(
            contentful_id: {eq: "49ijkz5uohoQayYQ0OoJxR"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
        contentfulHistorySection(
            contentful_id: {eq: "1LSAObhFSjgaMXrSSedPrj"}
            node_locale: {eq: $locale}
        ) {
            id
            title
            elements {
                text { raw }
                year
            }
        }
    }
`;